import React, {useContext} from "react"
import {styled} from '@mui/material'
import { Email } from '@mui/icons-material';
import { HashLink } from "react-router-hash-link";

import { MyColors } from "../../constants/MyColors"
import { ContentText, HeadingText } from "../../constants/MyFonts"
import MyButton from "../../components/form/MyButton"
import { AppContext } from "../../store/context/app-context"
import { iconSecondary } from "../../constants/MyStyles";

function ContactInfo(props) {

    const {
        theme
    } = useContext(AppContext);

    const ContactInfo = styled('div')({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        // position: 'absolute',
        // top: 100,
        // left:0,
        minHeightheight: 40,
        // padding: 8,
        // backgroundColor: 'rgba(255, 255, 255, 0.8)',
        borderRadius: 8,
        // marginLeft: 24,
        marginRight: 24
    })

    const ContactInfoSmall = styled('div')({
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        // width: 250,
        // padding: 8,
        // backgroundColor: 'rgba(255, 255, 255, 0.8)',
        borderRadius: 8,
        // margin: 24
    })

    const ImageContainer = styled('img')({
        width: 50,
        height: 50,
        borderRadius: 100,
        objectFit: 'cover',
    })

    const Container = styled('div')({
        display: 'flex',
        flexDirection: 'column ',
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        marginLeft: 24
    })

    const size = props.size

    return (
        <Container>
            <ContactInfoSmall>
                {/* <ImageContainer src={'../images/food.jpg'} /> */}
                <Email sx={iconSecondary} />
                <HeadingText style={{color: MyColors.primaryColors.secondary}}>appdesigney@admin.co.uk</HeadingText>
            </ContactInfoSmall>

            <HashLink style={{textDecoration: 'none'}} to = "#contact">
                <MyButton icon={'SupportAgent'} style={{width: 200}} type='primary' label='Contact Us' action={()=>{}} />
            </HashLink>
        </Container>

        
    )
}

export default ContactInfo