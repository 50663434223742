import React,{useContext, useEffect, useRef} from "react"
import {styled} from '@mui/material'
import { AnimatePresence, motion } from "framer-motion"

import { Banner_MainText, Banner_SecondaryMainText } from "../../constants/MyFonts"
import { AppContext } from "../../store/context/app-context";

function Title(props) {

    const { 
        initialAnimated,
    } = useContext(AppContext);

    const InfoBox = styled('div')({
        display: 'flex',
        flex: 3,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
        padding: 16,
        margin: 8,
        borderRadius: 4,
      });

      const InfoBoxSmall = styled('div')({
        display: 'flex',
        flex: 3,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
        padding: 16,
        margin: 8,
        borderRadius: 4,
      });

    const size = props.size

    const textSlideSnimation = {
        initial: { opacity: 0, y: 100 },
        animate: { opacity: 1, y: 0, transition: { delay: 0.3, duration: 1 } },
    }

    return (
        <>
            {
                size === 'small'?
                <InfoBoxSmall>
                </InfoBoxSmall>
                :
                <InfoBox>
                    {
                        initialAnimated?
                        <>
                            <Banner_MainText>
                                {props.mainTitle}
                            </Banner_MainText>
                            <Banner_SecondaryMainText>
                                {props.secondaryText}
                            </Banner_SecondaryMainText>
                        </>
                        :
                        <motion.div
                            variants={textSlideSnimation}
                            initial="initial"
                            animate="animate"
                            transition= "transition"
                            whileInView="animate"
                            viewport={{
                                once: true
                            }}
                        >
                            <Banner_MainText>
                                {props.mainTitle}
                            </Banner_MainText>
                            <Banner_SecondaryMainText>
                                {props.secondaryText}
                            </Banner_SecondaryMainText>
                        </motion.div>
                    }
                    
                </InfoBox>
            }
        </>
    )
}

export default Title