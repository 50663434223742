import React,{useEffect, useState} from "react"
import { motion } from "framer-motion";
import {styled} from '@mui/material'

import ContactInfo from "./banner/contactInfo";
import Title from "./banner/title";
import { Banner_MainText } from "../constants/MyFonts";

function HomepageBanner(props) {
    const ImageContainer = styled('img')({
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    })

    const fixed = props.fixed;

    const [contactSize, setContactSize] = useState('large');
    const [isScrolled, setScrolled] = useState(false);

    useEffect(() => {
        if(!fixed) {
            // Adding the scroll listener
            window.addEventListener('scroll', handleScroll, { passive: true });
            return () => {
                // Removing listener
                window.removeEventListener('scroll', handleScroll);
            };
        }
        else {
            setContactSize('small')
        }
    }, []);

    const handleScroll = () => {
        if(window.scrollY > 120) {
            setScrolled(true);
            setContactSize('small');
        } else {
            setScrolled(false);
            setContactSize('large');
        }
    }

    return (
        <motion.div 
            style={{position: 'sticky', top: 0}}
            initial={!fixed?{ height: 300 } : { height: 180 }}
            animate={!fixed? isScrolled? { height: 180 } : { height: 300 } : { height: 180 }}
            transition={{ duration: 1 }}
        >
            <ImageContainer src={'../images/background.png'} />
            
            <div style = {{display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', position: 'absolute', bottom: 20, left: 0, width: '100%', height: '100%'}}>
                
                <Title 
                    size={contactSize} 
                    mainTitle='Mobile / Web Application Experts' 
                    secondaryText = 'We create beautiful mobile & web applications designed to take your business further. Contact us to find out more.'
                />

                <motion.div 
                    style ={{display:'flex', flex: 1, flexDirection: 'row', justifyContent:'flex-start', alignItems: 'center', width: '100%', position: 'absolute', bottom: -10, left: 0}}
                    initial={{ scale: 1, x:0 }}
                    animate={isScrolled? { scale: 0.8, x:-30 } : { scale: 1 }}
                    transition={{ duration: 1 }}
                >
                    <ContactInfo size={contactSize} />
                    {
                        props.title&&
                        <Banner_MainText>{props.title}</Banner_MainText>
                    }
                </motion.div>
                
            </div>
        </motion.div>
    )
}   

export default HomepageBanner