import React,{useState, useEffect, useLayoutEffect, useContext} from "react"
import {styled} from '@mui/material'
import { AnimatePresence, motion } from "framer-motion"

import { MyColors } from "../constants/MyColors";
import AppLogo from "./AppLogo";
import NavBar from "./NavBar";
import { AppContext } from "../store/context/app-context";
import NavLinks from "./NavLinks";
import ThemeToggle from "./banner/themeToggle";

function Header(props) {

  const [isScrolled, setScrolled] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const { 
    theme,
    navOpen,
    setNavOpen,
    initialAnimated,
    setInitialAnimated
} = useContext(AppContext);

  const handleScroll = () => {
    if(window.scrollY > 250) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
    if(!initialAnimated) {
      setInitialAnimated(true);
    }
  }

useEffect(() => {
// Adding the scroll listener
window.addEventListener('scroll', handleScroll, { passive: true });

return () => {
    // Removing listener
    window.removeEventListener('scroll', handleScroll);
};
}, []);

useLayoutEffect(() => {
  function updateWidth() {
      setScreenWidth(window.innerWidth);
  }

  window.addEventListener('resize', updateWidth);
  updateWidth();
  return () => window.removeEventListener('resize', updateWidth);
}, []);

  const BannerStyled = styled('div')({
      flex: 1,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: 12,
      paddingBottom: 12,
      paddingLeft: 32,
      paddingRight: 32,
      backgroundColor: MyColors.primaryColors.main,
      boxShadow: 10,
    })

    const handleNavAnmation = () => {
      const newStatus = navOpen? false : true;
      setNavOpen(newStatus);
    }

    // if(!isScrolled) {
      return (
        <>
          <BannerStyled>
            {
              initialAnimated?
                <AppLogo />
              :
              <motion.div
                initial={{ opacity: 0, x: -100 }}
                animate={{ opacity: 1, x: 0 }}
                // exit={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
            >
              <AppLogo />
            </motion.div>
            }
            
            <NavBar handleNavAnmation = {handleNavAnmation} />

            <ThemeToggle />
          </BannerStyled>

          {/* <motion.div 
            style={{backgroundColor: MyColors.primaryColors.main}}
            initial={{height: 0}}
            animate={!navOpen? {height: 0} : {height: 170}}
            transition={{duration: 0.5}}
          > */}
            {
              screenWidth < 780&&
                <motion.div
                  layoutId="mainnav"
                  style={{backgroundColor: MyColors.primaryColors.main}}
                  initial={{opacity:0, height: 0, y: -400}}
                  animate={!navOpen? {opacity: 0, height: 0, y: -400} : {opacity:1, height: 170, y: 0}}
                  transition={{duration: 0.5}}
                >
                  <NavLinks displayDir = 'column' />
                </motion.div>
            }                    
          {/* </motion.div> */}
         </> 
      )
    // }
    // else {
    //   return (
    //     <>
    //       <BannerStyled>
    //         <AppLogo />
    //         <NavBar handleNavAnmation={handleNavAnmation} />
    //       </BannerStyled>

    //       <motion.div 
    //         style={{backgroundColor: MyColors.primaryColors.main}}
    //         initial= {{opacity:0}}
    //         animate={{opacity:1}}
    //       >
    //         {
    //           screenWidth < 780 && navOpen&&
    //             <NavLinks displayDir = 'column' />
    //         }                    
    //       </motion.div>
    //     </> 
    //   )
    // }
}

export default Header