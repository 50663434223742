import logo from './logo.svg';
import './App.css';
import {createTheme, ThemeProvider} from '@mui/material'
import {
  Routes, //replaces "Switch" used till v5
  Route,
} from "react-router-dom";

import React,{useState} from 'react'

import Home from "./screens/home";
import PrivacyPolicy from "./screens/privacypolicy";
import Chef from './screens/privacypolocies/chef'
import ActivityTracker from './screens/privacypolocies/activitytracker'

import {ScreenStyled} from './constants/MyStyles'
import Header from './components/header';
import Footer from './components/footer';

import {AppProvider} from './store/context/app-context';
import { MyColors } from './constants/MyColors.js';

const theme = createTheme({
  palette: {
    primary: {
      main: "#2e1667",
    },
    secondary: {
      main: "#c7d8ed",
    },
    typography: {
      FontFamily: [
        'Roboto'
      ],
      h4: {
        fontWeight: 600,
        fontSize: 28,
        lineHeight: '2rem'
      },
      h5: {
        fontWeight: 100,
        lineHeight: '2rem',
      }
    },
    body: {
      backgroundColor: 'pink'
    }
  },
})

function App() {
  const [name, setName] = useState('')
  return (
    <AppProvider>
      <div className="App" style={{display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: MyColors.primaryColors.main}} >
        {/* <ThemeProvider theme={theme}> */}
            <div style={{position: 'sticky', top: 0, zIndex: 1}}>
              <Header />
            </div>

            <ScreenStyled>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                    <Route path="/privacypolicy/chef" element={<Chef />} />
                    <Route path="/privacypolicy/activitytracker" element={<ActivityTracker />} />
                </Routes>
            </ScreenStyled>

            <Footer />
        {/* </ThemeProvider> */}
      </div>
    </AppProvider>
  );
}

export default App;
