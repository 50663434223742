import { NavigationLinks } from "../constants/MyFonts";
import React, {useContext, useState, useLayoutEffect} from "react"
import { HashLink } from "react-router-hash-link";

import {styled, Toolbar} from '@mui/material';
import { MyColors } from "../constants/MyColors";
import { AppContext } from "../store/context/app-context";

const NavLinks = (props) => {
    const Wrapper = styled('div')({
        display: 'flex',
        flex: 1,
        width: '100%',
        flexDirection: props.displayDir,
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: MyColors.primaryColors.main
    });

    const { 
        initialAnimated,
        setInitialAnimated,
        setNavOpen
    } = useContext(AppContext);

    return (
        <Wrapper>
            <HashLink style={{textDecoration: 'none'}} to = "/" onClick={() => {
                !initialAnimated&& setInitialAnimated(true); 
                setNavOpen(false);
            }}>
                <NavigationLinks>
                    Home
                </NavigationLinks>
            </HashLink>

            <HashLink style={{textDecoration: 'none'}} to = "/#portfolio" onClick={() => {
                !initialAnimated&& setInitialAnimated(true);
                setNavOpen(false);
            }}>
                <NavigationLinks>
                    Portfolio
                </NavigationLinks>
            </HashLink>

            <HashLink style={{textDecoration: 'none'}} to = "/#skills" onClick={() => {
                !initialAnimated&& setInitialAnimated(true);
                setNavOpen(false);
            }}>
                <NavigationLinks>
                    Expertise
                </NavigationLinks>
            </HashLink>

            <HashLink style={{textDecoration: 'none'}} to = "/#contact" onClick={() => {
                !initialAnimated&& setInitialAnimated(true);
                setNavOpen(false);
            }}>
                <NavigationLinks>
                    Contact Us
                </NavigationLinks>
            </HashLink>

            <HashLink style={{textDecoration: 'none'}} to = "/privacypolicy" onClick={() => {
                !initialAnimated&& setInitialAnimated(true);
                setNavOpen(false);
            }}>
                <NavigationLinks>
                    Privacy Policies
                </NavigationLinks>
            </HashLink>
        </Wrapper>
    )
}

export default NavLinks