import React, {useContext} from "react"
import {styled} from '@mui/material'

import { AppContext } from "../store/context/app-context"

function AppLogo(props) {

    const {
        theme,
     } = useContext(AppContext);

    const ImageContainer = styled('img')({
        // width: 50, 
        height: 50, 
        objectFit: 'cover',
    })

    return (
        <ImageContainer src={'../images/Logo.png'} />
        // <>
        //     {
        //         theme.label === 'light'?
        //         <ImageContainer src={'../images/LogoLight.png'} />
        //         :
        //         <ImageContainer src={'../images/Logo.png'} />
        //     }
        // </>
    )
}

export default AppLogo