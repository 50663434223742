export const MyColors = {
    colors: {
        black: '#222222',
        lightGrey: '#D2D2D2',
        white: '#FFFFFF',
        charcoal: '#3B3B3B'
    },
    primaryColors: {
        main: '#062F4E',
        main2: '#032136',
        secondary: '#FFE381',
        black: '#222222',
        background: '#FFF8E0'
    },
    textColors: {
        black: '#222222',
        pink: '#FF87AB',
        white: '#FFFFFF',
        linkHeader: '#FFFFFF',
        linkContent: '#016F0C',
        menu: '#031929'
    },
}

export const LightTheme = {
    label: 'light',
    background: MyColors.colors.white,
    text: MyColors.primaryColors.black,
    highlightedText: MyColors.textColors.main,
    headingText: MyColors.primaryColors.main2,
    link: MyColors.primaryColors.main,
    border: MyColors.primaryColors.main,
    input: MyColors.colors.white,
    cardStyle: {
        borderRadius: 8,
        boxShadow: `0px 0px 10px ${MyColors.primaryColors.main}`
    },
    dotActive: MyColors.primaryColors.main,
    dot: '#D5D5D5'
}

export const DarkTheme = {
    label: 'dark',
    background: MyColors.primaryColors.main2,
    text: MyColors.colors.white,
    highlightedText: MyColors.primaryColors.secondary,
    headingText: MyColors.primaryColors.secondary,
    link: MyColors.primaryColors.secondary,
    border: MyColors.colors.white,
    input: MyColors.colors.white,
    cardStyle: {
        borderRadius: 8,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: MyColors.colors.white,
    },
    dotActive: MyColors.colors.white,
    dot: '#838282'
}