import React, {useContext, useState, useLayoutEffect} from "react"
import {styled, Toolbar} from '@mui/material';
import { Menu, Close } from '@mui/icons-material';
import { AnimatePresence, motion } from "framer-motion"

import { MyColors } from "../constants/MyColors"
import { iconWhite } from "../constants/MyStyles";
import { AppContext } from '../store/context/app-context';
import NavLinks from "./NavLinks";

function NavBar(props) {

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useLayoutEffect(() => {
        function updateWidth() {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener('resize', updateWidth);
        updateWidth();
        return () => window.removeEventListener('resize', updateWidth);
    }, []);

    const BarStyled = styled('div')({
        flexDirection: 'column',
        paddingTop: 12,
        paddingBottom: 12,
        paddingLeft: 32,
        paddingRight: 32,
        ['@media (max-width:780px)']: {
            flexDirection: "row"
        }
    })

    const Icon = styled('div')({
        display: 'none',
        ['@media (max-width:780px)']: {
            display: 'block'
        },
        cursor: 'pointer'
    })

    const { 
        navOpen,
    } = useContext(AppContext);

    if(screenWidth > 780) {
        return (
            <BarStyled position="sticky" className="nav">
                <NavLinks displayDir = 'row' />
            </BarStyled>
        )
    }
    else {
        return (
            <>
                {
                    navOpen?
                    <motion.div
                        layoutId={'nav'}
                        initial={{ opacity: 0, rotate: -180 }}
                        animate={{ opacity: 1, rotate: 0 }}
                        transition={{ duration: 1 }}
                    >
                        <Icon 
                            style={{
                                "&:hover": {
                                    color: MyColors.textColors.link
                                },
                            }}
                            onClick={
                                props.handleNavAnmation
                            } 
                            position="sticky" 
                            className="nav">
                                <Close sx={iconWhite} />
                        </Icon>
                    </motion.div>
                    :
                    <motion.div
                        layoutId={'nav'}
                        initial={{ opacity: 0, rotate: 180 }}
                        animate={{ opacity: 1, rotate: 0 }}
                        transition={{ duration: 1 }}
                    >
                        <Icon 
                            onClick={
                                props.handleNavAnmation
                            } 
                            position="sticky" 
                            className="nav">
                                <Menu sx={iconWhite} />
                        </Icon>
                    </motion.div>
                }
            </>
        )
    }
}

export default NavBar