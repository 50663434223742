import {styled, Typography} from '@mui/material'
import {Link} from 'react-router-dom'

import { MyColors, LightTheme, DarkTheme } from './MyColors'
import '../fonts/SourceSansPro-Bold.ttf';
import '../fonts/SourceSansPro-Regular.ttf';

const FontFamily = 'sans-serif';

const smallText = 16;
const mediumText = 18;
const largeText = 22;
const xlargeText = 28;

// Banners
export const Banner_MainText = styled(Typography)({
  color: MyColors.colors.white,
  fontSize: 40,
  fontWeight: 'bold',
  fontFamily: FontFamily,
  textAlign: 'left',
  ['@media (max-width: 768px)'] :{
      fontSize: 24
  }
})

export const Banner_SecondaryMainText = styled(Typography)({
  color: MyColors.colors.white,
  fontSize: 22,
  fontWeight: 'bold',
  fontFamily: FontFamily,
  textAlign: 'left',
  ['@media (max-width: 768px)'] :{
    fontSize: 16
  }
})

// Headings
export const HeadingText = styled(Typography)({
  padding: "0.4rem",
  color: MyColors.primaryColors.black,
  fontSize: largeText,
  fontFamily: FontFamily,
  fontWeight: 'bold',
  textDecoration: 0,
})

export const HeadingTextBold = styled(Typography)({
  padding: "0.4rem",
  color: MyColors.primaryColors.black,
  fontSize: largeText,
  fontFamily: FontFamily,
  fontWeight: 'bolder',
  textDecoration: 0,
})

export const HeadingTextLargeBold = styled(Typography)({
  padding: "0.4rem",
  color: MyColors.primaryColors.black,
  fontSize: xlargeText,
  fontFamily: FontFamily,
  fontWeight: 'bolder',
  textDecoration: 0,
})

export const SubHeadingText = styled(Typography)({
  padding: "0.4rem",
  color: MyColors.primaryColors.black,
  fontSize: mediumText,
  fontFamily: FontFamily,
  fontWeight: 'bold',
  textDecoration: 0,
})


// Content
export const ContentText = styled(Typography)({
  padding: "0.4rem",
  color: MyColors.primaryColors.black,
  fontFamily: FontFamily,
  fontSize: smallText,
  textDecoration: 0,
  textAlign: 'left',
  fontWeight: 'normal',
})

export const ContentTextBold = styled(Typography)({
  padding: "0.4rem",
  color: MyColors.primaryColors.black,
  fontFamily: FontFamily,
  fontSize: smallText,
  textDecoration: 0,
  textAlign: 'left',
  fontWeight: 'bold',
})

// Link
export const ContentLinkText = styled(Link)({
  padding: "0.4rem",
  color: MyColors.textColors.menu,
  textDecoration: 0,
  fontFamily: FontFamily,
  fontSize: mediumText,
  fontWeight: 'bold',
})

export const NavigationLinks = styled(Typography)({
    cursor: "pointer",
    textDecoration: 0,
    fontFamily: FontFamily,
    fontSize: largeText,
    fontWeight: 'bold',
    marginLeft: 28,
    color: MyColors.primaryColors.secondary,
    "&:hover": {
        color: MyColors.colors.white
    },
    ['@media (max-width:780px)'] :{
        paddingBottom: "1rem"
    }
})

export const PrimaryButtonText = styled(Typography)({
  color: MyColors.colors.black,
  fontFamily: FontFamily,
  fontSize: smallText,
  fontWeight: 'bold',
})