import React,{useEffect, useContext} from "react"
import {styled} from '@mui/material'

import {ContentText} from '../constants/MyFonts'
import { MyColors } from "../constants/MyColors";
import {Link} from 'react-router-dom'
import { AppContext } from '../store/context/app-context';
import HomepageBanner from "../components/homepageBanner";

const LinkSectionContainer = styled(Link)({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textDecoration: 0,
  borderRadius: 4,
  margin: 16,
  backgroundColor: MyColors.primaryColors.secondary,
})

const Content = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  textDecoration: 0,
  borderRadius: 4,
})

function PrivacyPolicy() {

  const { 
    theme,
    setNavOpen
} = useContext(AppContext);

  useEffect(() => {
      setNavOpen(false);
  }, []);

  return (
    <>
      <HomepageBanner fixed={true} title='Privacy Policies' />

      <Content style={{backgroundColor: theme.background}}>
            <LinkSectionContainer variant="h6" className="navItem" to = "/privacypolicy/chef">
                  <ContentText style={{color: MyColors.colors.black}}>Meal Book - Expert Recipes</ContentText>
            </LinkSectionContainer>

            {/* <LinkSectionContainer variant="h6" className="navItem" to = "/privacypolicy/activitytracker">
                  <ContentText style={{color: theme.text}}>Activity Tracker</ContentText>
            </LinkSectionContainer> */}
      </Content>
    </>
  );
}

export default PrivacyPolicy;
