import React, { useContext, useEffect } from "react"
import {styled} from '@mui/material'

import { ContentText, ContentTextBold } from "../constants/MyFonts";
import SectionsTemplate from "./sections/sectionsTemplate";
import MyInput from "./form/MyInput";
import MyButton from "./form/MyButton";
import { AppContext } from "../store/context/app-context";

function ContactUs() {

    const submitForm = () => {
        const name = document.getElementById('name');
        const message = document.getElementById('message');
    }

    const { 
        theme,
    } = useContext(AppContext);

    const Wrapper = styled('div')({
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        padding: 16,
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor:  theme.border,
        borderRadius: 4,
    })

    const Form = styled('div')({
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '40%',
        marginTop: 16,
        marginBottom: 16,
        // borderWidth: 1,
        // borderStyle: 'solid',
        // borderColor:  MyColors.colors.white,
        // borderRadius: 4,
        ['@media (max-width:780px)'] :{
            width: '100%',
        },
    })

    // console.log(message)
    const content = <Wrapper id='contact'>
        <ContentText style={{color: theme.text}}> For any questions or a quote for your app/website contact me by email and I will get back to you as soon as I can.</ContentText>
        <ContentTextBold style={{color: theme.text}}>Email: appdesignery@admin.co.uk</ContentTextBold>
        {/* <Form>
            <MyInput
                id='name'
                type = 'text'
                // name='Your Name'
                // defaultValue={name}
                label='Your Name'
                placeholder='Add your name here...'
                // onChange={handleChange}
            />

            <MyInput
                id='message'
                type = 'textarea'
                // name='Your Name'
                // defaultValue={name}
                label='Message'
                placeholder='Add your message...'
                // onChange={handleChange}
            />

            <MyButton icon = '' type='primary' label='Submit' action={submitForm} />
        </Form> */}
    </Wrapper>

    return (
        <SectionsTemplate 
            title='Contact Us' 
            content={content} 
            backgroundColor={ theme.background } 
        />
    )
}

export default ContactUs