import React, {useContext} from "react"

import {HeadingTextBold, ContentText, HeadingText} from '../../constants/MyFonts'
import {PrimaryScreenStyled} from '../../constants/MyStyles'
import Logo from '../../images/icon.png'
import { AppContext } from "../../store/context/app-context"

function Chef() {
  const {
    theme
  } = useContext(AppContext)

  return (
    <>
    <PrimaryScreenStyled style={{backgroundColor: theme.background}}>
      <img src={Logo} alt="Logo" width={100} height={100} />
        <ContentText style={{color: theme.text}}>
            This app does not collect, store or share any of your personal data or any of the meals data you add into the app.
        </ContentText>
        <ContentText style={{color: theme.text}}>
            All data you enter into the app including; your personal information, meals, shopping list, calendar is only stored on your own device. If you change devices or uninstall then re-install the app any data you entered will be deleted.
        </ContentText>
        <ContentText style={{color: theme.text}}>
            This app uses your internet connection when adding meals from a link.
        </ContentText>
        <ContentText style={{color: theme.text}}>
            This app needs access to your camera so you can add pictures into the app. We do not store these pictures, the picture you add are only stored on your device. If you delete the picture from your phone it will no longer appear on the app.
        </ContentText>

        <HeadingText style={{backgroundColor: theme.heading}}>
            For any questions or help with the app you can contact us on <HeadingTextBold>admin@appdesignery.co.uk</HeadingTextBold>
        </HeadingText>
    </PrimaryScreenStyled> 
    </>
  );
}

export default Chef;
