import React from "react"
import {styled, Input} from '@mui/material'
import { SupportAgent } from '@mui/icons-material';

import { MyColors } from "../../constants/MyColors"
import { PrimaryButtonText } from "../../constants/MyFonts"
import { iconPrimary, iconSecondary } from "../../constants/MyStyles";

const PrimaryButton = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: ' center',
    alignItems: 'center',
    borderRadius: 4,
    padding: 8,
    marginTop: 8,
    marginBottom: 8,
    cursor: 'pointer',
    backgroundColor: MyColors.primaryColors.secondary
})

function MyButton(props) {

    const type = props.type;
    const icon = props.icon

    switch(type) {
        case 'primary':
            return (
                <PrimaryButton onClick={props.action}>
                    {
                        icon !== ''&&
                            <SupportAgent sx={iconPrimary} />
                    }
                    <PrimaryButtonText>{props.label}</PrimaryButtonText>
                </PrimaryButton>
            )
    }
}

export default MyButton