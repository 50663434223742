import React, {useContext, useState} from "react"
// import {styled, Input} from '@mui/material'
import { AppContext } from "../../store/context/app-context"
import styled from "styled-components"

import { MyColors } from "../../constants/MyColors"
import { ContentText } from "../../constants/MyFonts"

const Wrapper = styled.div`
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    marginTop: 8,
    marginBottom: 8,
`

const InputStyle = styled.input`
    min-width: 4rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: 0.8rem;
    margin: 0;
    border-radius: 0.2rem;
    border: 1px solid ${MyColors.colors.lightGrey};
    box-shadow:none;
    color: ${MyColors.colors.black};
    background-color: ${MyColors.colors.white};
`

const TextAreaStyle = styled.textarea`
    min-width: 4rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: 0.8rem;
    margin: 0;
    border-radius: 0.2rem;
    border: 1px solid ${MyColors.colors.lightGrey};
    box-shadow:none;
    color: ${MyColors.colors.black};
    background-color: ${MyColors.colors.white};
`

function MyInput(props) {

    const [text, setText] = useState('');
    const [textArea, setTextArea] = useState('');

    const {
        theme
    } = useContext(AppContext);

    // const Wrapper = styled('div')({
    //     display: 'flex',
    //     flex: 1,
    //     flexDirection: 'column',
    //     justifyContent: 'flex-start',
    //     alignItems: 'flex-start',
    //     width: '100%',
    //     marginTop: 8,
    //     marginBottom: 8,
    // })

    // const InputStyle = styled(Input)({
    //     display: 'flex',
    //     flex: 1,
    //     flexDirection: 'column',
    //     justifyContent: 'flex-start',
    //     alignItems: 'flex-start',
    //     width: '100%',
    //     padding: 8,
    //     borderRadius: 4,
    //     borderWidth: 1,
    //     borderColor:  theme.border,
    //     backgroundColor: theme.input
    // })

    // const TextAreaStyle = styled('textarea')({
    //     display: 'flex',
    //     flex: 1,
    //     flexDirection: 'column',
    //     justifyContent: 'flex-start',
    //     alignItems: 'flex-start',
    //     padding: 8,
    //     minWidth: 200,
    //     height: 150,
    //     borderWidth: 1,
    //     borderColor:  theme.border,
    //     borderRadius: 4,
    //     backgroundColor: theme.input
    // })

    if(props.type === 'text') {
        return (
            <Wrapper>
                <ContentText style={{color: theme.text}}>{props.label}</ContentText>
                <InputStyle
                    id={props.id}
                    type={props.type}
                    name={text}
                    value={text}
                    placeholder={props.placeholder}
                    onChange={val => setText(val.target.value)}
                />
            </Wrapper>
        )
    }
    else if(props.type === 'textarea') {
        return (
            <Wrapper>
                <ContentText style={{color: theme.text}}>{props.label}</ContentText>
                <TextAreaStyle
                    id={props.id}
                    type={props.type}
                    name={props.name}
                    value={textArea}
                    placeholder={props.placeholder}
                    onChange={val => setTextArea(val.target.value)}
                />
            </Wrapper>
        )
    }
}

export default MyInput